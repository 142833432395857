<script setup>

</script>

<template>
  <svg width="57" height="12" viewBox="0 0 57 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.31142 0.466797L6.29737 7.14127L9.22477 0.466797H11.391L11.7423 11.2982H9.69315L9.45896 3.92113L6.88285 9.89302H5.53624L2.90158 3.97968L2.66739 11.2982H0.676758L1.08659 0.466797H3.31142Z" fill="#374151"/>
    <path d="M14.5527 5.91167C14.5527 2.63298 17.0118 0.349609 20.1733 0.349609C23.3349 0.349609 25.7354 2.69153 25.7354 5.91167C25.7354 9.13181 23.3349 11.5323 20.1148 11.5323C16.9532 11.4737 14.5527 9.13181 14.5527 5.91167ZM23.5106 5.91167C23.5106 3.74539 22.1054 2.22315 20.1148 2.22315C18.1827 2.22315 16.719 3.74539 16.719 5.91167C16.719 8.07795 18.1242 9.60019 20.1148 9.60019C22.1054 9.60019 23.5106 8.0194 23.5106 5.91167Z" fill="#374151"/>
    <path d="M37.8547 2.34033H34.6931V11.2982H32.5268V2.34033H29.3652V0.466797H37.8547V2.34033Z" fill="#374151"/>
    <path d="M41.4263 5.91167C41.4263 2.63298 43.8853 0.349609 47.0469 0.349609C50.2085 0.349609 52.6089 2.69153 52.6089 5.91167C52.6089 9.13181 50.2085 11.5323 46.9883 11.5323C43.8267 11.4737 41.4263 9.13181 41.4263 5.91167ZM50.4427 5.91167C50.4427 3.74539 49.0375 2.22315 47.0469 2.22315C45.1148 2.22315 43.6511 3.74539 43.6511 5.91167C43.6511 8.07795 45.0562 9.60019 47.0469 9.60019C49.0375 9.60019 50.4427 8.0194 50.4427 5.91167Z" fill="#374151"/>
    <path d="M54.6582 10.4776C54.6582 9.83357 55.1851 9.30664 55.8292 9.30664C56.4732 9.30664 57.0001 9.83357 57.0001 10.4776C57.0001 11.1216 56.4732 11.6486 55.8292 11.6486C55.1266 11.6486 54.6582 11.1216 54.6582 10.4776Z" fill="#E6202D"/>
  </svg>
</template>
